import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { resetAll, RootState } from 'store';
import { Loading, QueryParams } from 'types';
import websiteBuilderService from 'services/website-builder.service';
import websiteBuilder from 'models/WebsiteBuilder';
interface WebsiteBuilderState {
    websiteBuilders: websiteBuilder[];
    websiteBuildersLoading: Loading;
    websiteBuildersCount: number;
}

const name = 'website-builder';
const initialState: WebsiteBuilderState = {
    websiteBuilders: [],
    websiteBuildersLoading: 'idle',
    websiteBuildersCount: 0,
};

const setState = (state: any, field: string, value: any) => {
  state[field] = value;
};

export const fetchWebsiteBuilders = createAsyncThunk(
  `${name}/list-of-website-builders`,
  async (query: QueryParams, { rejectWithValue, getState }) => {
    try {
      const response = await websiteBuilderService.getWebsiteBuilders(query);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const websiteBuilderSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // the resetAll extra reducer is mandatory
    builder.addCase(resetAll, () => initialState);
    builder
      .addCase(fetchWebsiteBuilders.pending, (state) => setState(state, 'websiteBuildersLoading', 'pending'))
      .addCase(fetchWebsiteBuilders.fulfilled, (state, action) => {
        setState(state, 'websiteBuildersLoading', 'idle');
        setState(state, 'websiteBuilders', action.payload.rows);
        setState(state, 'websiteBuildersCount', action.payload.count);
      })
      .addCase(fetchWebsiteBuilders.rejected, (state) => setState(state, 'websiteBuildersLoading', 'idle'));
  },
});
export const selectWebsiteBuilders = (state: RootState) => {
  return state.websiteBuilders.websiteBuilders;
};
export const selectWebsiteBuildersLoading = (state: RootState) => {
  return state.websiteBuilders.websiteBuildersLoading;
};

export const selectWebsiteBuildersTotal = (state: RootState) => {
  return state.websiteBuilders.websiteBuildersCount;
};

export default websiteBuilderSlice.reducer;
