import websiteBuilder from 'models/WebsiteBuilder';
import request from 'requesters/index';
import { PaginationData } from 'types';
const websiteBuilderService = {
  getWebsiteBuilders: async (params?: any) => {
    return await request.get<PaginationData<websiteBuilder>>('/web-builder', { params });
  },
};

export default websiteBuilderService;
